import React from 'react'
import BirthdayIndex from './module/BirthdayIndex'

const App = () => {
  return (
    <div>
      {/* <BirthdayIndex /> */}
    </div>
  )
}

export default App
